import data from "./sync_data.json";
import "./App.css";
import { SyncEvent } from "./lib/SyncEvent";
import {
  getMostRecentSync,
  getFourPreviousSyncs,
  getAverageDeltasInMs,
  estimateNextSync,
} from "./lib/challenges";
import { msToTimeString } from "./lib/conversions";
import Socials from "./components/socials";
// @ts-ignore
// CREDITS: undraw.co for image
import img from "./assets/undraw_Data_report_re_p4so.png";
import Accordion from "./components/accordion";
import TextAnswer from "./components/textAnswer";
import Navbar from "./components/navbar";

function App() {
  // get sorted data in wrapper classes, from newest to oldest
  const syncs = data
    .map((i) => new SyncEvent(i))
    .sort((a, b) => b.getEnd().getTime() - a.getEnd().getTime());
  return (
    <div className="App">
      <div className="nav-wrapper">
        <Socials />
        <Navbar />
      </div>
      <div className="container">
        <h1 className="main-title">Sync Data Dashboard</h1>
        <div className="row">
          <div className="column flex">
            <img className="header-img" src={img} alt="Data dashboard icon" />
          </div>
          <div className="column answer-section">
            <TextAnswer
              title="Most recent sync completed"
              answer={getMostRecentSync(syncs).strformat("end")}
            />
            <Accordion
              title="More recent syncs completed (newest to oldest)"
              list={getFourPreviousSyncs(syncs).map((i) => i.strformat("end"))}
            />
            <TextAnswer
              title="Average time between 10 most recent syncs"
              answer={msToTimeString(getAverageDeltasInMs(syncs))}
            />
            <TextAnswer
              title="Estimated next sync completion"
              answer={estimateNextSync(syncs)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
