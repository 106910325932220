import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
/**
 * For aesthetic purposes, returns a dark purple bar with umortgage socials, as seen on umortgage website
 */
export default function socials() {
  return (
    <div className="socials">
      <ul>
        <li>
          <a href="https://www.facebook.com/umortgageteam">
            <FaFacebookF size={22} />
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/umortgageteam">
            <FaInstagram size={22} />
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/umortgageteam">
            <FaLinkedin size={22} />
          </a>
        </li>
      </ul>
    </div>
  );
}
