export function dateToDateString(date: Date) {
  return date.toLocaleString("en-US", {
    timeZone: "America/new_york",
    timeZoneName: "short",
  });
}

export function msToTimeString(ms: number) {
  const minutes = Math.floor(ms / (1000 * 60)) % 60;
  const seconds = Math.floor(ms / 1000) % 60;

  return `${minutes} minutes, ${seconds} seconds`;
}
