import type { SyncEvent } from "./SyncEvent";

/**
 * Recursive helper function to calculate sum of time elapsed between
 * each SyncEvent.
 * @param syncs A list of SyncEvents ordered from oldest to newest
 * @param accumulator An accumulator to store the previous computed value for
 * each recursive call
 * @returns The sum of times elapsed between each SyncEvent
 */
export function sumDeltas(syncs: SyncEvent[], accumulator = 0): number {
  const current = syncs[0],
    next = syncs[1];
  if (!current || !next) {
    // base case. If !next, the array length has reached 1,
    // and there is no next sync to check the time between.
    //
    // !current should never be reachable unless the input was
    // an empty array, but better safe than sorry
    return accumulator;
  }

  const delta = current.calcDelta(next);
  return sumDeltas(syncs.slice(1), accumulator + delta);
}
