import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

export type AccordionProps = {
  list: string[];
  title: string;
};
export default function Accordion({ list, title }: AccordionProps) {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  return (
    <div>
      <p
        className="answer-label accordion-title"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{title}</span>
        <span>
          <Chevron />
        </span>
      </p>
      <ul
        className={"accordion-body " + (isOpen ? "open" : "closed")}
        aria-hidden={!isOpen}
      >
        {list.map((item, index) => (
          <li key={`${title}-${index}`}>{item}</li>
        ))}
      </ul>
    </div>
  );

  function Chevron() {
    if (isOpen) {
      return <FaChevronUp size={22} />;
    } else {
      return <FaChevronDown size={22} />;
    }
  }
}
