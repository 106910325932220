import type { SyncEvent } from "./SyncEvent";
import { dateToDateString } from "./conversions";
import { sumDeltas } from "./utils";

/**
 * ALL challenges assume input is list of SyncEvents pre-ordered *newest to oldest*.
 * This is computed in App.tsx and passed into each function.
 *
 * Theoretically, the sorting algorithm could be abstracted
 * and applied at the beginning of each below algorithm,
 * but this is unnecessary and assuming larger inputs
 * could be computationally expensive
 */

/**
 * CHALLENGE #1: Get the most recent completed sync time.
 * @param syncs A list of SyncEvent instances, ordered from newest to oldest
 * @returns A SyncEvent instance representing the most recent sync
 */
export function getMostRecentSync(syncs: SyncEvent[]) {
  return syncs[0];
}

/**
 * CHALLENGE #2: Get four previous SyncEvents up to n-1.
 * @param syncs A list of SyncEvent instances, ordered from newest to oldest
 * @returns n-5 to n-1 SyncEvents
 */
export function getFourPreviousSyncs(syncs: SyncEvent[]) {
  return syncs.slice(1, 5);
}

/**
 * CHALLENGE #3: Calculate the average time between the 10 most recent syncs
 * @param syncs A list of SyncEvent instances, ordered from newest to oldest
 * @param limit The number of syncs to limit the operation to.
 * @returns the average time elapsed between the 10 most recent syncs
 */
export function getAverageDeltasInMs(syncs: SyncEvent[], limit: number = 10) {
  if (syncs.length < 2 || limit < 2) {
    // guard clause
    // Function doesn't make sense with a length of < 2
    // there are no gaps between 1 or 0 items to analyze
    // prevents div by 0 error
    return 0;
  }

  // select only n most recent syncs, default 10
  // reverse order so it is from oldest to newest
  const syncsUpToLimit = syncs.slice(0, limit).reverse();

  // calculate sum of deltas
  const sum = sumDeltas(syncsUpToLimit);

  // return average of sum/(count - 1)
  // we're subtracting 1 from the count because
  // given n items, there are only n-1 gaps between those items
  // so for a list of 10, the average gap between them will be SUM(deltas)/9
  return sum / (syncsUpToLimit.length - 1);
}

/**
 * CHALLENGE #4: Formulate an educated estimation of the next completed sync time.
 *  - Calc `next_complete_sync` as average gap between sync events (computed in last step)
 *    plus average duration of syncs
 *  @param syncs A list of SyncEvent instances, ordered from newest to oldest
 * @returns a locale string representing the estimated next sync completion
 */
export function estimateNextSync(syncs: SyncEvent[]) {
  // average time between most recent syncs,
  // calculated from last step
  const deltasAvgMs = getAverageDeltasInMs(syncs);

  // get average ms duration of a sync
  const durationsMs = syncs.map((i) => i.getDuration());
  const durationSumMs = durationsMs.reduce((prev, curr) => prev + curr, 0);
  const durationAvgMs = Math.round(durationSumMs / syncs.length);

  // last sync end as reference point
  const mostRecentEnd = syncs[0].getEnd();
  const estimateMs = mostRecentEnd.getTime() + durationAvgMs + deltasAvgMs;
  const estimateDate = new Date(estimateMs);
  return dateToDateString(estimateDate);
}
