export type Sync = {
  sync_start_date: string;
  sync_start_time: string;
  sync_end_date: string;
  sync_end_time: string;
};

/**
 * Convenience class that creates dates out
 * of the sync objects and provides methods to help with
 * storing, comparing, and formatting sync dates.
 */
export class SyncEvent {
  start: Date;
  end: Date;

  /**
   * Accepts an object of type Sync as the only parameter
   */
  constructor({
    sync_start_date,
    sync_start_time,
    sync_end_date,
    sync_end_time,
  }: Sync) {
    const strings = {
      start: `${sync_start_date} ${sync_start_time} UTC`,
      end: `${sync_end_date} ${sync_end_time} UTC`,
    };

    this.start = new Date(strings.start);
    this.end = new Date(strings.end);
  }

  getStart() {
    return this.start;
  }

  getEnd() {
    return this.end;
  }

  /**
   * Calculate the time difference between when the next sync starts,
   * and when the current one ends.
   * @param nextSyncEvent a SyncEvent instance representing the next sync
   * in an ordered list going from oldest to newest
   * @returns An integer representing time in ms between when
   * the current sync date ends and the next sync date starts.
   */
  calcDelta(nextSyncEvent: SyncEvent) {
    const upperBound = nextSyncEvent.getStart().getTime(),
      lowerBound = this.getEnd().getTime();
    return upperBound - lowerBound;
  }

  /**
   * Get the SyncEvent instance's duration in ms
   * @returns integer representing ms duration
   * between the start and end of the sync
   */
  getDuration() {
    return this.end.getTime() - this.start.getTime();
  }

  /**
   *
   * @param bounding Which of the SyncEvent's time value should be used for the calculation
   * @returns A locale string representing the EST date and time
   * of either the start or end of the SyncEvent instance
   *
   * [Documentation for toLocaleString](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString)
   */
  strformat(bounding: "start" | "end") {
    const date = bounding === "start" ? this.getStart() : this.getEnd();

    return date.toLocaleString("en-US", {
      timeZone: "America/new_york",
      timeZoneName: "short",
    });
  }
}
