//@ts-ignore
import logo from "../assets/Logo.webp";

/**
 * A simple navbar instance with only a logo rendered
 */
export default function Navbar() {
  return (
    <nav>
      <ul>
        <li>
          <a href="#">
            <img src={logo} alt="Logo" />
          </a>
        </li>
      </ul>
    </nav>
  );
}
