export type TextAnswerProps = {
  title: string;
  answer: string;
};

export default function TextAnswer({ title, answer }: TextAnswerProps) {
  return (
    <div className="answer-wrapper">
      <p className="answer-label">{title}</p>
      <p className="answer">{answer}</p>
    </div>
  );
}
